<template>
  <div>
    <div v-if="list.length > 0" class="pageList">
      <div
        v-for="(item, i) in list"
        v-show="item.title"
        :key="i"
        class="page"
        @click="$router.push(item.path)"
      >
        <div class="pageTitle">
          <img class="img" :src="require('@/assets/img/' + item.icon + '.png')" alt="" />{{
            item.title
          }}
        </div>
        <div class="pageDesc">{{ item.content }}</div>
      </div>
    </div>
    <div v-else class="noPower">暂无权限，请联系管理员开通</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list1: [
        {
          title: '短信模板',
          content: '自定义编辑短信发送模板，满足不同场景短信推送需求',
          path: '/msgTemplate',
          icon: 'msg',
          nodeCode: 'YY_MARKET_MSGTEMPLATE',
        },
        {
          title: '智能名片',
          content: '自动识别员工信息，一键生成微信名片',
          path: '/smartCard',
          icon: 'card',
          nodeCode: 'YY_MARKET_SMARTCARD',
        },
        {
          title: '智能话术',
          content: '咨询话术、销售话术、再转化话术一应俱全，满足各场景营销沟通',
          path: '/smartSpeech',
          icon: 'speak',
          nodeCode: 'YY_MARKET_SMARTSPEECH',
        },
        {
          title: '问答库',
          content: '客户问答快捷回复话术，提高沟通效率',
          path: '/q_a_Library',
          icon: 'ku',
          nodeCode: 'YY_MARKET_Q_ALIBRARY',
        },
        {
          title: '触达',
          content: '根据用户标签匹配收信人群，支持文案、发送时间自定义',
          path: '/touch',
          icon: 'chu',
          nodeCode: 'YY_MARKET_TOUCH',
        },
      ],
      list: [],
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  mounted() {
    this.powerList.forEach((e) => {
      this.list1.forEach((v) => {
        if (e.nodeCode === v.nodeCode) {
          this.list.push(v)
        }
      })
    })
  },
}
</script>

<style lang="less" scoped>
.pageList {
  background-color: #fff;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  .page {
    background-color: #669cff;
    cursor: pointer;
    width: 334px;
    height: 154px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    padding: 0 30px;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 60px;
    margin-bottom: 60px;
    .pageTitle {
      display: flex;
      align-items: center;
      .img {
        width: 30px;
        margin-right: 16px;
      }
      font-size: 20px;
      font-weight: bold;
    }
    .pageDesc {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
